import React from 'react'
import './assets/fonts/Helvetica.css'
import './assets/fonts/Helvetica-bold.css'
import './assets/fonts/Helvetica-light.css'
import './assets/fonts/Helvetica-black.css'
import './styles/page00.css'
import './styles/page02.css'
import './App.css'
import $ from 'jquery'
import Page from './scripts/page'
import Music from './scripts/music'
import MainScript from './scripts/mainScript'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tempMusic from './assets/musics/music.mp3'
import Sketch from 'react-p5'
import SketchPage from './scripts/sketch'

class App extends React.Component {
    targetElement = null
    constructor(props) {
        super(props)
        this.state = {
            sketchClass: new SketchPage(),
            drawPage: new Page(),
            p5Canvas: "",
        }
        this.state.drawPage.setting()
    }
    movePage(pos){
        this.state.pageClass.pageMovePosition(pos)
        if(this.state.pageClass.pageNum === 2) {
            enableBodyScroll(this.targetElement)
            console.log("scroll: enable")
        }
        else {
            disableBodyScroll(this.targetElement)
            console.log("scroll: disable")
        }
        this.state.pageClass.pageRefresh()
    }
    musicPlay() {
        if(this.state.musicClass && tempMusic) {
            if(this.state.musicClass.playing) {
                return 0
            }
            else {
                setTimeout( () => {
                    this.musicPlay()
                }, 1500)
            }
            this.state.musicClass.play()
        }
        else {
            setTimeout( () => {
                this.musicPlay()
            }, 1500)
        }
    }
    componentDidMount() {
        const h = window.innerHeight
        window.scrollTo(0, 0)
        $("#page3Wrap").css("height", h)
        $("#page4Wrap").css("height", h - 96)
        this.targetElement = document.querySelector('#page2Wrap') // 스크롤 가능 객체
        disableBodyScroll(this.targetElement)
        this.state.drawPage.draw()
        this.state.drawPage.load()
        this.state.drawPage.animation()
        this.setState({
            pageClass: new MainScript()
        }, () => {
            window.addEventListener( 'scroll', this.state.pageClass.scrollEvent, false )
            this.state.pageClass.pageRefresh()
        })
        this.setState({
            musicClass: new Music($('#equalizer'), {loop: true})
        }, async () => {
            this.state.musicClass.onended()
            this.state.musicClass.reset()
            this.state.musicClass.loadAudio(await tempMusic)
            this.musicPlay()
        })
        window.addEventListener( 'resize', this.state.drawPage.onWindowResize, false )
    }
    componentDidUpdate() {
    }
    disableGuide(id) {
        $(`#${id}`).css("opacity", "0")
        $(`#${id}`).css("pointer-events", "none")
        this.state.sketchClass.nextWord()
    }
    onMouseMove(event) {
        this.state.drawPage.mouseMove(event)
        if(this.state.pageClass) {
            if(this.state.drawPage.isIntersects && this.state.pageClass.pageNum === 1) {
                document.body.style.cursor = "pointer"
            }
            else {
                document.body.style.cursor = "auto"
            }
        }
    }
    onMouseClick(event) {
        this.state.drawPage.mouseClick(event)
    }
    closeViewer() {
        this.state.drawPage.viewerOn = false
        this.state.drawPage.viewerNumber = 0

        $("#viewerWrap").css("pointer-events", "none")
        $("#viewerWrap").css("opacity", "0")
    }
    setup = (p5, canvasParentRef) => {
        this.setState({p5Canvas: canvasParentRef})
        this.state.sketchClass.setup(p5, canvasParentRef)
    }
    draw = (p5) => {
        this.state.sketchClass.draw(p5)
    }
    mousePressed = (btId) => {
        if ($("#bt0>p").html() === "PROJECT") {
            this.movePage(1)
        }
        else if ($("#bt0>p").html() === "ABOUT ME") {
            this.movePage(3)
        }
        else if ($("#bt0>p").html() === "CONTACT") {
            this.movePage(4)
        }
        else if ($("#bt0>p").html() === "CONCEPT") {
            this.movePage(2)
        }
        this.state.sketchClass.mousePressed(btId)
    }
    mouseDragged = (p5) => {
        this.state.sketchClass.mouseDragged(p5)
    }
    // keyPressed = (p5) => {
    //     this.state.sketchClass.keyPressed(p5)
    // }
    // windowResized = (p5) => {
    //     this.state.sketchClass.setup(p5, this.state.p5Canvas)
    // }
    viewerPageMove = (dir) => {
        if (dir) {
            if(this.state.drawPage.viewerPage === "web") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[0].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "motionGraphic") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[1].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "profileVideo") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[2].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "HomeComing") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[3].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "playButten") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[4].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "5cut") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[5].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "nutry") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[6].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "taja") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[7].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
            else if(this.state.drawPage.viewerPage === "restAI") {
                if(this.state.drawPage.viewerNumber >= this.state.drawPage.bgImgs[8].length - 1) {
                    this.state.drawPage.viewerNumber = 0
                }
                else {
                    this.state.drawPage.viewerNumber += 1
                }
            }
        }
        else {
            if(this.state.drawPage.viewerNumber) {
                if(this.state.drawPage.viewerPage === "web") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[0].length - 1
                }
                else if(this.state.drawPage.viewerPage === "motionGraphic") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[1].length - 1
                }
                else if(this.state.drawPage.viewerPage === "profileVideo") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[2].length - 1
                }
                else if(this.state.drawPage.viewerPage === "HomeComing") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[3].length - 1
                }
                else if(this.state.drawPage.viewerPage === "playButten") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[4].length - 1
                }
                else if(this.state.drawPage.viewerPage === "5cut") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[5].length - 1
                }
                else if(this.state.drawPage.viewerPage === "nutry") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[6].length - 1
                }
                else if(this.state.drawPage.viewerPage === "taja") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[7].length - 1
                }
                else if(this.state.drawPage.viewerPage === "restAI") {
                    this.state.drawPage.viewerNumber = this.state.drawPage.bgImgs[8].length - 1
                }
            }
            else {
                this.state.drawPage.viewerNumber -= 1
            }
        }
        this.state.drawPage.viewerDraw()

    }
    musicStop = () => {
        if(this.state.musicClass.playing) {
            this.state.musicClass.context.close()
            this.state.musicClass.playing = false
        }
        // else {
        //     this.state.musicClass.onended()
        //     this.state.musicClass.reset()
        //     this.state.musicClass.loadAudio(tempMusic)
        //     console.log(this.state.musicClass.sourceNode)
        //     this.musicPlay()
        // }
        // if(this.state.musicClass.playing) {
        //     this.state.musicClass.playing = false
        //     this.state.musicClass.onended()
        // }
    }
    render() {
        return (
            <div className="mainWrap" onMouseMove={this.onMouseMove.bind(this)}>
                <div id="mainGuideWrap" className="guideWrap" onClick={this.disableGuide.bind(this, "mainGuideWrap")}>
                    <div className="background"></div>
                    <div className="centerGuide"></div>
                    <div className="rightGuide"></div>
                </div>
                <div id="projectGuideWrap" className="guideWrap" onClick={this.disableGuide.bind(this, "projectGuideWrap")}>
                    <div className="background"></div>
                    <div className="leftGuide"></div>
                    <div className="centerGuide"></div>
                    <div className="rightGuide"></div>
                </div>
                <div id="fixedWrap">
                    <div id="logoWrap"></div>
                    <div id="navWrap">
                        <div id="menu1" onClick={this.movePage.bind(this, 0)}>
                            <p>MAIN</p>
                            <div className="squere"></div>
                        </div>
                        <div id="menu2" onClick={this.movePage.bind(this, 1)}>
                            <p>PROJECT</p>
                            <div className="squere"></div>
                        </div>
                        <div id="menu3" onClick={this.movePage.bind(this, 2)}>
                            <p>CONCEPT</p>
                            <div className="squere"></div>
                        </div>
                        <div id="menu4" onClick={this.movePage.bind(this, 3)}>
                            <p>ABOUT ME</p>
                            <div className="squere"></div>
                        </div>
                        <div id="menu5" onClick={this.movePage.bind(this, 4)}>
                            <p>CONTACT</p>
                            <div className="squere"></div>
                        </div>
                    </div>
                    <div id="musicWrap">
                        <p onClick={this.musicStop.bind(this)}>mahalia - I Wish I Missed My Ex</p>
                        {/* <input type="range" id="volume"></input> */}
                        <div id="equalizer">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div id="scrollAnimate">
                        <div className="arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div id="viewerWrap">
                    <div className="background" onClick={this.closeViewer.bind(this)}></div>
                    <div id="viewer">
                        <iframe id="videoPlayer" src="https://www.youtube.com/embed/AtsiYAkzbfk" frameborder="0" title="videoPlayer"></iframe>
                        <div className="background" onClick={this.closeViewer.bind(this)}></div>
                        <div id="toLeftWrap" className="arrowWrap" onClick={this.viewerPageMove.bind(this, 0)}>
                            <div id="toLeft"></div>
                        </div>
                        <div id="toRightWrap" className="arrowWrap" onClick={this.viewerPageMove.bind(this, 1)}>
                            <div id="toRight"></div>
                        </div>
                    </div>
                </div>
                <div id="page0Wrap" className="pageWrap interactive">
                    <Sketch
                        setup={this.setup}
                        draw={this.draw}
                        // mousePressed={this.mousePressed}
                        // keyPressed={this.keyPressed}
                        // windowResized={this.setup}
                    />
                    <div id="btWrap">
                        <div id="bt0" className='bt' onClick={this.mousePressed.bind(this, 0)}>
                            <div className="background"></div>
                            <p>Who are you?</p>
                        </div>
                        <div id="bt1" className='bt' onClick={this.mousePressed.bind(this, 1)}>
                            <div className="background"></div>
                            <p>What is your position?</p>
                        </div>
                    </div>
                </div>
                <div id="page1Wrap" onClick={this.onMouseClick.bind(this)} className="pageWrap interactive"></div>
                <div id="page2Wrap" className="pageWrap interactive">
                    <div className="backgroundWrap">
                        <div className="background"></div>
                    </div>
                    <div id="page2ItemContainer" className="container">
                        <div className="item00 item">
                            <h2>DESIGN CONCEPT</h2>
                            <h4>
                                The site was created as a portfolio site for designer and front-end developer Lee Jeong-woo.
                            </h4>
                            <h4>
                                I used webGL in various ways to emphasize that I am a front-end developer with designer skills. Also, I set CI/CD that builds react scripts through node.js docker and uploads them to AWS S3 using github action.
                            </h4>
                            <h4>
                                If you want to know more, please press the button below.
                            </h4>
                            <div className="button" onClick={this.movePage.bind(this, 3)}>
                                <p>GO TO THE "ABOUT ME" PAGE</p>
                            </div>
                        </div>
                        <div className="item01 item">
                            <div className="img"></div>
                        </div>
                        <div className="item02 item">
                            <div className="img"></div>
                        </div>
                        <div className="item03 item">
                            <div className="img"></div>
                        </div>
                        <div className="item04 item">
                            <h2>CONTENTS CONFIGURATION</h2>
                            <h4>
                                It is a composition that answers questions about me with 2D animation using p5.js and introduces my project with 3D webGL using THREE.JS.
                            </h4>
                            <h4>
                                Also, there are pages on the bottom that can introduce me and contact me.
                            </h4>
                            <h4>
                                All of these pages are configured to be moved directly through the navigation on the right.
                            </h4>
                        </div>
                        <div className="item05 item">
                            <h2>DESIGN DIRECTION</h2>
                            <div className="img"></div>
                            <h4 className="light">
                                HELVETICA LIGHT abcdefg
                            </h4>
                            <h4 className="bold">
                                HELVETICA BOLD abcdefg
                            </h4>
                            <h4 className="black">
                                HELVETICA BLACK abcdefg
                            </h4>
                            <h4>
                                It uses achromatic color and main color combination and sansherif typeface to enhance refinement, I actively used webGL to visualize as many pages as possible.
                            </h4>
                            <h4>
                                also used a lot of new technologies that might be unfamiliar to users, so used images to guide us on how to use them so that we could approach users in a friendly way.
                            </h4>
                        </div>
                        <div className="item06 item">
                            <h4>
                                Technically, I tried to use the latest one. A dynamic DOM management was realized using the 'react library'. It was reused and modularized as much as possible to facilitate maintenance when programming.
                            </h4>
                        </div>
                        <div className="item07 item">
                            <div className="img"></div>
                        </div>
                        <div className="item08 item">
                            <div className="img"></div>
                        </div>
                        <div className="item09 item">
                            <div className="img"></div>
                        </div>
                        <div className="item10 item">
                            <div className="img"></div>
                        </div>
                        <div className="item11 item">
                            <h2>DEVELOPMENT STACK</h2>
                            <h4>
                                UI: React /
                                2D webGL: P5.JS /
                                3D webGL: THREE.JS /
                                Mail API: Mailgun /
                                Massage API: Solapi /
                                CI/CD: Github Actions /
                                Git repository: Github /
                                Frontend server: AWS S3 /
                                Editor: Visual studio code /
                                Other: Jquery, body-scroll-lock
                            </h4>
                        </div>
                        <div className="item12 item">
                            <div className="img"></div>
                        </div>
                    </div>
                </div>
                <div id="page3Wrap" className="pageWrap interactive">
                    <div className="aboutMeTitle">
                        <h1>LEE JEONG-WOO</h1>
                        <h4>FRONTEND DEVELOPER &amp; DESIGNER</h4>
                    </div>
                    <div className="aboutMeContents">
                        <div>
                            <h2>INTRODUCTION</h2>
                            <h3>Name</h3>
                            <h4>Lee Jeong-woo</h4>
                            <h3>EDUCATION</h3>
                            <h4>Anyang University, South korea</h4>
                            <h4>The Division of Digital Media Design</h4>
                            <h4>The Division of Intelligence Information Content</h4>
                            <h3>LANGUAGE</h3>
                            <h4>Korean · Japanese</h4>
                            <h2>AWARDS AND HONORS</h2>
                            <h3>ACADEMIC CONFERENCE AWARD</h3>
                            <h4>Design and implementation of location information service based on place image deep learning</h4>
                            <h4>Excellence Award in the Academic Conference Paper Contest</h4>
                            <h4>Korea Society of IT Services</h4>
                        </div>
                        <div>
                            <h2>DEVELOPMENT SKILL &amp; EXPERIENCE</h2>
                            <h3>REACT.js</h3>
                            <h4>There are many project experiences using fact and mobile cross-platform development, real native, and tried to optimize resources using modularization and components.</h4>
                            <h3>AWS</h3>
                            <h4>I have used most of the functions of AWS and have many experience in implementing serverless services as s3, lambda, and RDS.</h4>
                            <h3>ETC.</h3>
                            <h4>Responsive web · Single page application · p5.js · THREE.js · MySQL · Serverless · Docker · CI/CD with Github Action · Android Studio</h4>
                            <h2>DESIGN SKILL &amp; EXPERIENCE</h2>
                            <h3>VARIED EXPERIENCE</h3>
                            <h4>Having extensive design knowledge through various projects. I experienced posters, videos, 3d, UI/UX, etc.</h4>
                        </div>
                    </div>
                </div>
                <div id="page4Wrap" className="pageWrap interactive">
                    <p className="contactTitle">
                        CONTACT
                    </p>
                    <div className="contactContents">
                        <div className="viaSNS">
                            <h2>VIA SNS</h2>
                            <h4>Phone number</h4>
                            <input type="tel" placeholder="ex) +00 00-0000-0000"></input>
                            <h4>Contents</h4>
                            <textarea rows="12"></textarea>
                            <input type="button" value="SEND"></input>
                        </div>
                        <div className="viaMail">
                            <h2>VIA E-MAIL</h2>
                            <h4>E-mail address</h4>
                            <input type="email" placeholder="ex) abcd123@gmail.com"></input>
                            <h4>Contents</h4>
                            <textarea rows="12"></textarea>
                            <input type="button" value="SEND"></input>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <p>This webpage is optimized for 1920 X 1080 resolution.
                        And Internet Explorer and old browser may not work properly.</p>
                    <p>© LEE JEONG-WOO 2020</p>
                </div>
            </div>
        )
    }
}

export default App
