import * as THREE from "three"
import { THREEGLTFLoader} from 'three-loaders'
import OrbitControls from 'three-orbitcontrols'
import PointLightObject from './components/PointLightObject'
import SpotLightObject from './components/SpotLightObject'
import capsule from '../assets/models/capsule.glb'
import Web from './web'
import Video from './video'
import Mobile from './mobile'
import $ from 'jquery'

import web00 from '../assets/images/web/web00.png'
import web01 from '../assets/images/web/web01.png'
import web02 from '../assets/images/web/web02.png'
import web03 from '../assets/images/web/web03.png'
import web04 from '../assets/images/web/web04.png'
import web05 from '../assets/images/web/web05.png'
import web06 from '../assets/images/web/web06.png'

import motionGraphic00 from '../assets/images/motionGraphic/motionGraphic00.JPG'
import motionGraphic01 from '../assets/images/motionGraphic/motionGraphic01.JPG'

import profileVideo00 from '../assets/images/profileVideo/profileVideo00.JPG'
import profileVideo01 from '../assets/images/profileVideo/profileVideo01.JPG'
import profileVideo02 from '../assets/images/profileVideo/profileVideo02.JPG'
import profileVideo03 from '../assets/images/profileVideo/profileVideo03.JPG'

import homeComing00 from '../assets/images/homeComing/homeComing00.JPG'
import homeComing01 from '../assets/images/homeComing/homeComing01.JPG'
import homeComing02 from '../assets/images/homeComing/homeComing02.JPG'
import homeComing03 from '../assets/images/homeComing/homeComing03.JPG'

import cut00 from '../assets/images/5cut/5cut00.JPG'
import cut01 from '../assets/images/5cut/5cut01.JPG'
import cut02 from '../assets/images/5cut/5cut02.JPG'

import nutry00 from '../assets/images/nutry/nutry00.png'
import nutry01 from '../assets/images/nutry/nutry01.png'
import nutry02 from '../assets/images/nutry/nutry02.png'
import nutry03 from '../assets/images/nutry/nutry03.png'
import nutry04 from '../assets/images/nutry/nutry04.png'
import nutry05 from '../assets/images/nutry/nutry05.png'
import nutry06 from '../assets/images/nutry/nutry06.png'
import nutry07 from '../assets/images/nutry/nutry07.png'
import nutry08 from '../assets/images/nutry/nutry08.png'
import nutry09 from '../assets/images/nutry/nutry09.png'

import taja00 from '../assets/images/taja/taja00.jpg'
import taja01 from '../assets/images/taja/taja01.jpg'
import taja02 from '../assets/images/taja/taja02.jpg'
import taja03 from '../assets/images/taja/taja03.png'
import taja04 from '../assets/images/taja/taja04.jpg'
import taja05 from '../assets/images/taja/taja05.png'

import restAI00 from '../assets/images/restAI/restAI00.png'
import restAI01 from '../assets/images/restAI/restAI01.png'
import restAI02 from '../assets/images/restAI/restAI02.png'
import restAI03 from '../assets/images/restAI/restAI03.png'
import restAI04 from '../assets/images/restAI/restAI04.png'
import restAI05 from '../assets/images/restAI/restAI05.png'
import restAI06 from '../assets/images/restAI/restAI06.png'
import restAI07 from '../assets/images/restAI/restAI07.png'
import restAI08 from '../assets/images/restAI/restAI08.png'
import restAI09 from '../assets/images/restAI/restAI09.png'
import restAI10 from '../assets/images/restAI/restAI10.png'
import restAI11 from '../assets/images/restAI/restAI11.png'
import restAI12 from '../assets/images/restAI/restAI12.png'

const isDev = false
class Page {
    constructor() {
        // === 필수 변수 선언 ===
        this.scene = new THREE.Scene()
        this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 )
        this.renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.nowIndicate = ''
        this.viewerOn = false
        this.viewerNumber = 0
        this.viewerPage = ""
        this.bgImgs = [
            [
                web00,
                web01,
                web02,
                web03,
                web04,
                web05,
                web06,
            ],
            [
                motionGraphic00,
                motionGraphic01,
            ],
            [
                profileVideo00,
                profileVideo01,
                profileVideo02,
                profileVideo03,
            ],
            [
                homeComing00,
                homeComing01,
                homeComing02,
                homeComing03,
            ],
            [
                "https://www.youtube.com/embed/AtsiYAkzbfk", // 프로필
                "https://www.youtube.com/embed/kFEVz0x0fpc", // 졸업영상
                "https://www.youtube.com/embed/zTh1hLYwGt0", // 모션그래픽
            ],
            [
                cut00,
                cut01,
                cut02,
            ],
            [
                nutry00,
                nutry01,
                nutry02,
                nutry03,
                nutry04,
                nutry05,
                nutry06,
                nutry07,
                nutry08,
                nutry09,
            ],
            [
                taja00,
                taja01,
                taja02,
                taja03,
                taja04,
                taja05,
            ],
            [
                restAI00,
                restAI01,
                restAI02,
                restAI03,
                restAI04,
                restAI05,
                restAI06,
                restAI07,
                restAI08,
                restAI09,
                restAI10,
                restAI11,
                restAI12,
            ],
        ]
    }
    load = async () => {
    }
    mouseMove = (event) => {
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    }
    mouseClick = (event) => {
        // Object name
        // web = web
        // video = motionGraphic, profileVideo, HomeComing, playButten, 5cut
        // mobile = nutry, taja, restAI
        if(this.nowIndicate) {
            this.viewerOn = true
            this.viewerNumber = 0
            this.viewerPage = this.nowIndicate
            this.viewerDraw()
            $("#viewerWrap").css("pointer-events", "auto")
            $("#viewerWrap").css("opacity", "1")
        }
    }
    viewerDraw = () => {
        if(this.viewerPage === "playButten") {
            $("#videoPlayer").css("display", 'block')
            this.bgImg = this.bgImgs[4][this.viewerNumber]
            console.log(this.bgImg)
            $("#videoPlayer").attr("src", this.bgImg)
        }
        else {
            $("#videoPlayer").css("display", 'none')
            if(this.viewerPage === "web") {
                this.bgImg = this.bgImgs[0][this.viewerNumber]
            }
            else if(this.viewerPage === "motionGraphic") {
                this.bgImg = this.bgImgs[1][this.viewerNumber]
            }
            else if(this.viewerPage === "profileVideo") {
                this.bgImg = this.bgImgs[2][this.viewerNumber]
            }
            else if(this.viewerPage === "HomeComing") {
                this.bgImg = this.bgImgs[3][this.viewerNumber]
            }
            else if(this.viewerPage === "5cut") {
                this.bgImg = this.bgImgs[5][this.viewerNumber]
            }
            else if(this.viewerPage === "nutry") {
                this.bgImg = this.bgImgs[6][this.viewerNumber]
            }
            else if(this.viewerPage === "taja") {
                this.bgImg = this.bgImgs[7][this.viewerNumber]
            }
            else if(this.viewerPage === "restAI") {
                this.bgImg = this.bgImgs[8][this.viewerNumber]
            }
        }
        $("#viewer > .background").css("background-image", `url(${this.bgImg})`)
    }
    setting = () => {
        // === 필수 변수 세팅 ===
        this.camera.position.set(0, 10, 10) // 카메라 위치
        this.renderer.setSize( window.innerWidth, window.innerHeight ) // renderer 사이즈 지정
        this.renderer.outputEncoding = THREE.sRGBEncoding
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        // === 변수 선언 ===
        this.clock = new THREE.Clock()
        this.GLTFloader = new THREEGLTFLoader()
        this.textureLoader = new THREE.TextureLoader()
        this.camera.updateMatrixWorld();
        // === 렌더 세팅 ===
        this.render = () => {
            this.raycaster.setFromCamera( this.mouse, this.camera )
            var intersects = this.raycaster.intersectObjects( this.scene.children, true )
            // Object name
            // web = web
            // video = motionGraphic, profileVideo, HomeComing, playButten, 5cut
            // mobile = nutry, taja, restAI
            if(intersects.filter((intersect) => {
                return intersect.object.name
            }).length) {
                this.isIntersects = true
                for ( var i = 0; i < intersects.length; i++ ) {
                    if(intersects[i].object.name) {
                        this.nowIndicate = intersects[i].object.name
                    }
                    // intersects[ i ].object.material.color.set( 0xff0000 );
                }
            }
            else {
                this.isIntersects = false
                this.nowIndicate = ''
            }
            this.renderer.setSize( window.innerWidth, window.innerHeight )
            this.renderer.render( this.scene, this.camera )
        }
        // === 카메라 컨트롤 ===
        this.controls = new OrbitControls( this.camera, this.renderer.domElement )
        this.controls.addEventListener( 'change', this.render ) // use if there is no animation loop
        this.controls.minDistance = 0
        this.controls.maxDistance = 5
        this.controls.target.set( 0, 10, 0 )
        this.controls.update()
        // === 라이트 ===
        // capsule size는 x, z 170
        const ambientLight = new THREE.AmbientLight( 0xffffff, 0.1)
        this.scene.add( ambientLight )
        const lightObject1 = new PointLightObject({
            posX: 0,
            posY: 90,
            posZ: 0,
            opacity: 0.4,
            distance: 1000,
            cast: false,
            shadowRadius: 1
        })
        this.spotlights =
        [
            new SpotLightObject({
                posX: 0,
                posY: 80,
                posZ: -40,
                tarX: 0,
                tarY: -20,
                tarZ: -80,
                opacity: 0.4,
                distance: 0,
                angle: Math.PI/4,
                penumbra: 1,
                cast: false,
                shadowRadius: 1
            }),
            new SpotLightObject({
                posX: 0,
                posY: 80,
                posZ: 40,
                tarX: 0,
                tarY: -20,
                tarZ: 80,
                opacity: 0.4,
                distance: 0,
                angle: Math.PI/4,
                penumbra: 1,
                cast: false,
                shadowRadius: 1
            }),
            new SpotLightObject({
                posX: 40,
                posY: 80,
                posZ: 0,
                tarX: 80,
                tarY: -20,
                tarZ: 0,
                opacity: 0.4,
                distance: 0,
                angle: Math.PI/4,
                penumbra: 1,
                cast: false,
                shadowRadius: 1
            }),
            new SpotLightObject({
                posX: -40,
                posY: 80,
                posZ: 0,
                tarX: -80,
                tarY: -20,
                tarZ: 0,
                opacity: 0.4,
                distance: 0,
                angle: Math.PI/4,
                penumbra: 1,
                cast: false,
                shadowRadius: 1
            }),
        ]
        this.scene.add(
            lightObject1.light,
            this.spotlights[0].light.target,
            this.spotlights[0].light,
            this.spotlights[1].light.target,
            this.spotlights[1].light,
            this.spotlights[2].light.target,
            this.spotlights[2].light,
            this.spotlights[3].light.target,
            this.spotlights[3].light,
            // lightObject1.helper(),
            // this.lightObject2.helper(),
            // this.lightObject3.helper(),
            // this.lightObject4.helper(),
            // this.lightObject5.helper(),
        )
    }
    draw = async () => {
        const pagePosition =
        [
            {
                posX: 0,
                posY: 10,
                posZ: -15,
                rotY: THREE.Math.degToRad(0),
            },
            {
                posX: 20 * Math.sqrt(3),
                posY: 10,
                posZ: 20,
                rotY: THREE.Math.degToRad(-120),
            },
            {
                posX: -20 * Math.sqrt(3),
                posY: 10,
                posZ: 20,
                rotY: THREE.Math.degToRad(-240),
            },
            {
                posX: -20,
                posY: 10,
                posZ: 0,
                rotY: THREE.Math.degToRad(-270),
            },
            {
                posX: 0,
                posY: 10,
                posZ: -20,
                rotY: THREE.Math.degToRad(0),
            },
        ]
        if(isDev) {
            const page03 = new Mobile()
            page03.setting()
            page03.draw()
            page03.scene.position.x = pagePosition[4].posX
            page03.scene.position.y = pagePosition[4].posY
            page03.scene.position.z = pagePosition[4].posZ
            page03.scene.rotation.y = pagePosition[4].rotY
            this.scene.add( page03.scene )
        }
        else {
            // === 오브젝트 DRAW ===
            const page01 = new Web()
            page01.setting()
            page01.draw()
            page01.scene.position.x = pagePosition[0].posX
            page01.scene.position.y = pagePosition[0].posY
            page01.scene.position.z = pagePosition[0].posZ
            page01.scene.rotation.y = pagePosition[0].rotY
            this.scene.add( page01.scene )
    
            const page02 = new Video()
            page02.setting()
            page02.draw()
            page02.scene.scale.x = page02.scene.scale.x * 2
            page02.scene.scale.y = page02.scene.scale.y * 2
            page02.scene.position.x = pagePosition[1].posX
            page02.scene.position.y = pagePosition[1].posY
            page02.scene.position.z = pagePosition[1].posZ
            page02.scene.rotation.y = pagePosition[1].rotY
            this.scene.add( page02.scene )
    
            const page03 = new Mobile()
            page03.setting()
            page03.draw()
            page03.scene.position.x = pagePosition[2].posX
            page03.scene.position.y = pagePosition[2].posY
            page03.scene.position.z = pagePosition[2].posZ
            page03.scene.rotation.y = pagePosition[2].rotY
            this.scene.add( page03.scene )
        }

        // === 배경 DRAW ===
        this.GLTFloader.load( capsule, (gltf) => {
            gltf.scene.children[0].side = THREE.DoubleSide
            gltf.scene.children[0].children[0].children[0].children[0].children[0].name = ''
            this.scene.add(gltf.scene.children[0])
        })

        // === 컨테이너 정의 및 삽입 ===
        this.container = document.getElementById('page1Wrap') // 넣을 html DOM 정의
        this.container.appendChild(this.renderer.domElement); // html DOM에 삽입
    }
    animation = () => {
        // ===== 프레임 별 실행 =====
        const animate = () => {
            // === 콜백함수 ===
            requestAnimationFrame( animate );
            // === 3D모델 애니메이션 ===
            let delta = this.clock.getDelta();
            if ( this.mixer ) this.mixer.update( delta );
            // === Helper Update ===
            // this.spotlights[0].helper.update()
            // this.spotlights[1].helper.update()
            // this.spotlights[2].helper.update()
            // this.spotlights[3].helper.update()
            // === 렌더 함수 호출 ===
            this.render();
        };
        if(this.action) {
            this.action.play()
        }
        animate();
    }
    onWindowResize = () => {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize( window.innerWidth, window.innerHeight )
    }
}

export default Page;