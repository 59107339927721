import * as THREE from "three"
import { THREEGLTFLoader} from 'three-loaders'
import cubeObject from './components/cubeObject'
import cylinderObject from './components/cylinderObject'
import PointLightObject from './components/PointLightObject'
import SpotLightObject from './components/SpotLightObject'
import video_background from '../assets/maps/background.png'
import transparent from '../assets/maps/transparent.png'
import layout7 from '../assets/maps/video/layout7.png'
import layout9 from '../assets/maps/video/layout9.png'
import layout14 from '../assets/maps/video/layout14.png'
import layout16 from '../assets/maps/video/layout16.png'
import layout18 from '../assets/maps/video/layout18.png'
import layout_spec7 from '../assets/maps/video/layout_spec7.png'
import layout_spec9 from '../assets/maps/video/layout_spec9.png'
import layout_spec14 from '../assets/maps/video/layout_spec14.png'
import layout_spec16 from '../assets/maps/video/layout_spec16.png'
import layout_spec18 from '../assets/maps/video/layout_spec18.png'

class Video {
    constructor() {
        // === 필수 변수 선언 ===
        this.scene = new THREE.Scene()
    }
    setting = () => {
        this.GLTFloader = new THREEGLTFLoader()
        this.textureLoader = new THREE.TextureLoader()
        this.cubeTextureLoader = new THREE.CubeTextureLoader()
    }
    draw = async () => {
        const mainlightObject1 = new SpotLightObject({
            posX: 80,
            posY: -10,
            posZ: 60,
            tarX: 0,
            tarY: 0,
            tarZ: 0,
            opacity: 0.15,
            distance: 130,
            angle: Math.PI/3,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject2 = new SpotLightObject({
            posX: -30,
            posY: -30,
            posZ: 40,
            tarX: 0,
            tarY: 0,
            tarZ: 0,
            opacity: 0.15,
            distance: 130,
            angle: Math.PI/3,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject3 = new SpotLightObject({
            posX: -10,
            posY: 30,
            posZ: 30,
            tarX: 0,
            tarY: 0,
            tarZ: 0,
            opacity: 0.15,
            distance: 130,
            angle: Math.PI/3,
            penumbra: 1,
            cast: false,
        })
        this.scene.add(
            mainlightObject1.light,
            // mainlightObject1.helper,
            mainlightObject2.light,
            // mainlightObject2.helper,
            mainlightObject3.light,
        )

        const dataList = [
            // # width
            // # height
            // # posX
            // # posY
            // # posZ
            // # 1
            [
                4.1,
                2.2,
                -15,
                9,
                -6,
                false,
            ],
            // # 2
            [
                2.7,
                4.1,
                -8.6,
                11,
                7,
                false,
            ],
            // # 3
            [
                5.7,
                3,
                -4.2,
                11.5,
                -3,
                false,
            ],
            // # 4
            [
                5.7,
                4.3,
                1.2,
                -4.6,
                -6,
                false,
            ],
            // # 5
            [
                5.2,
                1.6,
                5.8,
                7.9,
                3,
                false,
            ],
            // # 6
            [
                5.2,
                4.9,
                18.3,
                14.6,
                -7,
                false,
            ],
            // ## 7
            [
                8.3,
                6.4,
                18,
                3.7,
                9,
                layout7,
                layout_spec7,
            ],
            // # 8
            // [
            //     1.4,
            //     11.8,
            //     9.3,
            //     -0.1,
            //     0,
                // false,
            // ],
            // ## 9
            [
                7.2,
                9.2,
                5.8,
                -1.4,
                12,
                layout9,
                layout_spec9,
            ],
            // # 10
            [
                5.2,
                3,
                5.8,
                -12.7,
                5,
                false,
            ],
            // # 11
            [
                2.6,
                3,
                27.4,
                -4.5,
                -9,
                false,
            ],
            // # 12
            // [
            //     5.2,
            //     4.2,
            //     11.3,
            //     -8.3,
            //     -4,
                // false,
            // ],
            // # 13
            // [
            //     5.6,
            //     3.3,
            //     0.2,
            //     -4.3,
            //     -10,
                // false,
            // ],
            // ## 14
            [
                6.85,
                7,
                -1.2,
                -7.7,
                8,
                layout14,
                layout_spec14,
            ],
            // # 15
            [
                4.2,
                1.85,
                -8.85,
                -7,
                2,
                false,
            ],
            // ## 16
            [
                12,
                7.8,
                -5.2,
                4.6,
                -3,
                layout16,
                layout_spec16,
            ],
            // # 17
            [
                2.8,
                2.15,
                -11.3,
                2.5,
                3,
                false,
            ],
            // ## 18
            [
                8.1,
                11.1,
                -20.85,
                -3.5,
                -2,
                layout18,
                layout_spec18,
            ],
            // # 19
            // [
            //     11.9,
            //     1.4,
            //     -13.05,
            //     -3.3,
            //     -9,
                // false,
            // ],
            // # 20
            [
                2.8,
                1.65,
                -14.2,
                -7.2,
                -11,
                false,
            ],
        ]
        // cube draw
        for(let i = 0; i < dataList.length; i++) {
            let tempCube = 0
            if(dataList[i][5]) {
                tempCube = new cubeObject({
                    width: dataList[i][0],
                    height: dataList[i][1],
                    depth: 0.3,
                    posX: dataList[i][2],
                    posY: dataList[i][3],
                    posZ: dataList[i][4],
                    color: 0xffffff,
                    emissive: 0x000000,
                    opacity: 1,
                    frontOpacity: 0.85,
                    sideOpacity: 0.25,
                    mapEachSide: true,
                    frontMap: this.textureLoader.load(dataList[i][5]),
                    frontSpecMap: this.textureLoader.load(dataList[i][6]),
                    sideMap: this.textureLoader.load(video_background),
                    backMap: this.textureLoader.load(transparent),
                })
            }
            else {
                tempCube = new cubeObject({
                    width: dataList[i][0],
                    height: dataList[i][1],
                    depth: 0.3,
                    posX: dataList[i][2],
                    posY: dataList[i][3],
                    posZ: dataList[i][4],
                    color: 0xffffff,
                    emissive: 0x000000,
                    opacity: 1,
                    frontOpacity: 0.15,
                    sideOpacity: 0.25,
                    mapEachSide: true,
                    frontMap: this.textureLoader.load(video_background),
                    sideMap: this.textureLoader.load(video_background),
                    backMap: this.textureLoader.load(transparent),
                })
            }
            if(i === 6) {
                tempCube.cube.name = 'motionGraphic'
            }
            else if(i === 7) {
                tempCube.cube.name = 'profileVideo'
            }
            else if(i === 10) {
                tempCube.cube.name = 'HomeComing'
            }
            else if(i === 12) {
                tempCube.cube.name = 'playButten'
            }
            else if(i === 14) {
                tempCube.cube.name = '5cut'
            }
            this.scene.add(tempCube.cube)
        }
        // line draw
        for(let i = 0; i < dataList.length; i++) {
            let tempCylinder1 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: dataList[i][2] - (dataList[i][0] / 2) + 0.3,
                posY: dataList[i][3] + (dataList[i][1] / 2) + 25,
                posZ: dataList[i][4],
                opacity: 0.7,
                color: 0x000000,
            })
            let tempCylinder2 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: dataList[i][2] + (dataList[i][0] / 2) - 0.3,
                posY: dataList[i][3] + (dataList[i][1] / 2) + 25,
                posZ: dataList[i][4],
                opacity: 0.7,
                color: 0x000000,
            })
            this.scene.add(tempCylinder1.cylinder, tempCylinder2.cylinder)
        }
    }
}

export default Video