import * as THREE from "three"
import { THREEGLTFLoader} from 'three-loaders'
import cubeObject from './components/cubeObject'
import cylinderObject from './components/cylinderObject'
import PointLightObject from './components/PointLightObject'
import SpotLightObject from './components/SpotLightObject'
import background from '../assets/maps/background.png'
import transparent from '../assets/maps/transparent.png'
import layout1 from '../assets/maps/mobile/layout1.png'
import layout4 from '../assets/maps/mobile/layout4.png'
import layout7 from '../assets/maps/mobile/layout7.png'

class Video {
    constructor() {
        // === 필수 변수 선언 ===
        this.scene = new THREE.Scene()
    }
    setting = () => {
        this.GLTFloader = new THREEGLTFLoader()
        this.textureLoader = new THREE.TextureLoader()
        this.cubeTextureLoader = new THREE.CubeTextureLoader()
    }
    draw = async () => {
        const dataList = [
            // # width
            // # height
            // # posX
            // # posY
            // # posZ
            // # frontMap
            // # frontSpecMap
            // # 0
            [
                9,
                19.5,
                -20,
                0,
                0,
                false,
            ],
            // # 1
            [
                9,
                19.5,
                -10,
                10.25,
                0,
                layout1,
            ],
            // # 2
            [
                9,
                19.5,
                -10,
                -10.25,
                0,
                false,
            ],
            // # 3
            [
                9,
                19.5,
                0,
                20.5,
                0,
                false,
            ],
            // # 4
            [
                9,
                19.5,
                0,
                0,
                0,
                layout4,
            ],
            // # 5
            [
                9,
                19.5,
                0,
                -20.5,
                0,
                false,
            ],
            // # 6
            [
                9,
                19.5,
                10,
                10.25,
                0,
                false,
            ],
            // # 7
            [
                9,
                19.5,
                10,
                -10.25,
                0,
                layout7,
            ],
            // # 8
            [
                9,
                19.5,
                20,
                0,
                0,
                false,
            ],
        ]
        const tempScene = new THREE.Scene()
        // cube draw
        for(let i = 0; i < dataList.length; i++) {
            let tempCube = 0
            if(dataList[i][5]) {
                tempCube = new cubeObject({
                    width: dataList[i][0],
                    height: dataList[i][1],
                    depth: 0.3,
                    posX: dataList[i][2],
                    posY: dataList[i][3],
                    posZ: dataList[i][4],
                    color: 0xffffff,
                    emissive: 0x000000,
                    opacity: 1,
                    frontOpacity: 1,
                    sideOpacity: 0.25,
                    mapEachSide: true,
                    frontMap: this.textureLoader.load(dataList[i][5]),
                    frontSpecMap: this.textureLoader.load(dataList[i][6]),
                    sideMap: this.textureLoader.load(background),
                    backMap: this.textureLoader.load(transparent),
                })
            }
            else {
                tempCube = new cubeObject({
                    width: dataList[i][0],
                    height: dataList[i][1],
                    depth: 0.3,
                    posX: dataList[i][2],
                    posY: dataList[i][3],
                    posZ: dataList[i][4],
                    color: 0xffffff,
                    emissive: 0x000000,
                    opacity: 1,
                    frontOpacity: 0.15,
                    sideOpacity: 0.25,
                    mapEachSide: true,
                    frontMap: this.textureLoader.load(background),
                    sideMap: this.textureLoader.load(background),
                    backMap: this.textureLoader.load(transparent),
                })
            }
            if(i === 1) {
                tempCube.cube.name = 'nutry'
            }
            else if(i === 4) {
                tempCube.cube.name = 'taja'
            }
            else if(i === 7) {
                tempCube.cube.name = 'restAI'
            }
            tempScene.add(tempCube.cube)
        }
        // line draw
        for(let i = 0; i < dataList.length; i++) {
            let tempCylinder1 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: dataList[i][2] - (dataList[i][0] / 2) + 0.3,
                posY: dataList[i][3] + (dataList[i][1] / 2) + 25,
                posZ: dataList[i][4],
                opacity: 0.7,
                color: 0x000000,
            })
            let tempCylinder2 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: dataList[i][2] + (dataList[i][0] / 2) - 0.3,
                posY: dataList[i][3] + (dataList[i][1] / 2) + 25,
                posZ: dataList[i][4],
                opacity: 0.7,
                color: 0x000000,
            })
            tempScene.add(
                tempCylinder1.cylinder,
                tempCylinder2.cylinder,
            )
        }
        tempScene.position.y = tempScene.position.y
        tempScene.position.z = tempScene.position.z
        tempScene.scale.x = tempScene.scale.x * 1.3
        tempScene.scale.y = tempScene.scale.y * 1.3
        // tempScene.rotation.x = THREE.Math.degToRad(-60)
        // tempScene.rotation.y = THREE.Math.degToRad(0)
        // tempScene.rotation.z = THREE.Math.degToRad(40)
        this.scene.add(tempScene)
    }
}

export default Video