import $ from 'jquery'
import logo_dark from '../assets/svgs/logo_dark.svg'
import logo from '../assets/svgs/logo.svg'

class MainScript {
    constructor() {
        this.pageNum = 0
        this.page = []
        this.firstMain = 1
        this.firstProject = 1
        for(let i = 0; i < $('.mainWrap').children('.pageWrap').length; i++) {
            this.page[i] = $('#page' + String(i) + 'Wrap').offset().top
        }
        this.items = []
        for(let i = 0; i < $('#page2ItemContainer').children('*').length; i++) {
            let temp = '0'
            if(i < 10) {
                temp = '0' + String(i)
            }
            else {
                temp = String(i)
            }
            this.items.push(0)
            this.items[i] = [$('.item' + temp).offset().top, false]
        }
        this.pageRefresh()
    }
    pageRefresh = () => {
        const blackSkin = () => {
            $("#logoWrap").css("background-image", `url(${logo_dark})`)
            $("#fixedWrap").css("color", "#000000")
            $("#navWrap .squere").css("background-color", "#000000")
            $("#equalizer .bar").css("background-color", "#000000")
            $("#fixedWrap").css("font-family", 'Helvetica-bold')
        }
        const whiteSkin = () => {
            $("#logoWrap").css("background-image", `url(${logo})`)
            $("#fixedWrap").css("color", "#ffffff")
            $("#navWrap .squere").css("background-color", "#ffffff")
            $("#equalizer .bar").css("background-color", "#ffffff")
            $("#fixedWrap").css("font-family", 'Helvetica-light')
        }
        if(this.pageNum === 0) {
            if(this.firstMain) {
                $("#mainGuideWrap").css("opacity", "1")
                $("#mainGuideWrap").css("pointer-events", "auto")
                this.firstMain = 0
            }
            whiteSkin()
            $("#scrollAnimate").css("opacity", "0")
        }
        else if (this.pageNum === 1) {
            if(this.firstProject) {
                $("#projectGuideWrap").css("opacity", "1")
                $("#projectGuideWrap").css("pointer-events", "auto")
                this.firstProject = 0
            }
            blackSkin()
            $("#scrollAnimate").css("opacity", "0")
        }
        else if (this.pageNum === 2) {
            whiteSkin()
            $("#scrollAnimate").css("opacity", "0.8")
        }
        else if (this.pageNum === 3) {
            whiteSkin()
            $("#scrollAnimate").css("opacity", "0")
        }
        else if (this.pageNum === 4) {
            blackSkin()
            $("#scrollAnimate").css("opacity", "0")
            $("#musicWrap p").css("color", "#ffffff")
            $("#equalizer .bar").css("background-color", "#ffffff")
        }
        for(let i = 1; i < $('.mainWrap').children('.pageWrap').length; i++) {
            this.page[i] = $('#page' + String(i) + 'Wrap').offset().top
        }
        for(let i = 0; i < $('#page2ItemContainer').children('*').length; i++) {
            let temp = '0'
            if(i < 10) {
                temp = '0' + String(i)
            }
            else {
                temp = String(i)
            }
            this.items[i][0] = $('.item' + temp).offset().top
        }
    }
    pageMovePosition = (pos) => {
        this.pageNum = pos
        $('html').animate({scrollTop : this.page[this.pageNum]}, 400);
    }
    scrollEvent = () => {
        this.pageRefresh()
        let scrollNow = window.scrollY
        let inActive = false
        if(!inActive) {
            inActive = true
            for(let i = 0; i < this.items.length; i++) {
                if(!this.items[i][1]) {
                    if(scrollNow + 700 > this.items[i][0]) {
                        this.items[i][1] = true
                        let temp = '0'
                        if(i < 10) {
                            temp = '0' + String(i)
                        }
                        else {
                            temp = String(i)
                        }
                        $('.item' + temp).css('top', this.items[i][0] - this.page[2] - 100)
                        $('.item' + temp).css('opacity', 1)
                    }
                }
            }
            inActive = false
        }
    }
}

export default MainScript