import * as THREE from "three";

class cylinderObject {
    constructor({
        radius, height, segment = 64, posX = 0, posY = 0, posZ = 0,
        rotX = 0, rotY = 0, rotZ = 0, cast = true, receive = true,
        color = 0x888888, emissive = 0x000000, shininess = 50,
        opacity = 1, transparent = true,
        map = false, norMap = false, aoMap = false,
        dispMap = false, specMap = false,
    }) {
        this.Geometry = new THREE.CylinderGeometry( radius, radius, height, segment );
        this.Material = new THREE.MeshLambertMaterial( {
            color: color,
            emissive: emissive,
            opacity: opacity,
            transparent: transparent,
        })
        this.cylinder = new THREE.Mesh( this.Geometry, this.Material )
        this.cylinder.position.set(posX, posY, posZ)
        this.cylinder.rotation.x = THREE.Math.degToRad(rotX)
        this.cylinder.rotation.y = THREE.Math.degToRad(rotY)
        this.cylinder.rotation.z = THREE.Math.degToRad(rotZ)
        this.cylinder.castShadow = cast
        this.cylinder.receiveShadow = receive
    }
}

export default cylinderObject