import * as THREE from "three";

class PointLightObject {
    constructor({
        posX = 0, posY = 0, posZ = 0, cast = true,
        color = 0xffffff, opacity = 1, distance = 1000,
        shadowMapSizeWidth = 512,
        shadowMapSizeHeight = 512,
        shadowCameraNear = 0.5,
        shadowCameraFar = 500,
        shadowRadius = 8,
    }) {
        this.light = new THREE.PointLight( color, opacity, distance );
        this.light.position.set( posX, posY, posZ )
        this.light.castShadow = cast
        this.light.shadow.mapSize.width = shadowMapSizeWidth
        this.light.shadow.mapSize.height = shadowMapSizeHeight
        this.light.shadow.camera.near = shadowCameraNear
        this.light.shadow.camera.far = shadowCameraFar
        this.light.shadow.radius = shadowRadius;
        this.helper = new THREE.PointLightHelper( this.light, 2 )
    }
}

export default PointLightObject