import * as THREE from "three";

class cubeObject {
    constructor({
        width, height, depth = 0.5, posX = 0, posY = 0, posZ = 0,
        rotX = 0, rotY = 0, rotZ = 0, cast = true, receive = true,
        specular = 0xffffff, color = 0x888888, emissive = 0x000000, shininess = 50,
        opacity = 1, transparent = true,
        map = false, norMap = false, aoMap = false,
        sideOpacity = 0.1, frontOpacity = 1,
        dispMap = false, specMap = false, mapEachSide = false,
        frontMap = false, frontSpecMap = false, backMap = false, sideMap = false,
    }) {
        this.Geometry = new THREE.BoxGeometry( width, height, depth, 1, 1, 1 )
        if (mapEachSide) {
            let materials = []
            this.Geometry.faces.forEach(function (face, i) {
                face.materialIndex = Math.floor((i / 2) % 6);
            })
            for (let i = 0; i < 6; i++) {
                materials.push(
                    new THREE.MeshPhongMaterial({
                        specular: specular,
                        color: color,
                        emissive: emissive,
                        shininess: shininess,
                        opacity: opacity,
                        transparent: transparent,
                        side: THREE.DoubleSide,
                    }),
                )
                if ( i < 4 ) {
                    materials[i].opacity = sideOpacity
                    materials[i].map = sideMap
                }
                else if (i === 4) {
                    materials[i].opacity = frontOpacity
                    materials[i].map = frontMap
                    materials[i].specularMap = frontSpecMap
                }
                else if (i === 5) {
                    materials[i].map = backMap
                }
            }
            this.cube = new THREE.Mesh( this.Geometry, materials )
            // this.cube.position.set(posX, posY, posZ)
            // this.cube.rotation.x = THREE.Math.degToRad(rotX)
            // this.cube.rotation.y = THREE.Math.degToRad(rotY)
            // this.cube.rotation.z = THREE.Math.degToRad(rotZ)
            // this.cube.castShadow = cast
            // this.cube.receiveShadow = receive
        }
        else {
            this.Material = new THREE.MeshPhongMaterial({
                specular: specular,
                color: color,
                emissive: emissive,
                shininess: shininess,
                opacity: opacity,
                transparent: transparent,
                side: THREE.DoubleSide,
            })
            if (map) {
                this.Material.map = map
                this.Material.map.wrapS = THREE.RepeatWrapping
                this.Material.map.wrapT = THREE.RepeatWrapping
                this.Material.map.repeat.set( 1, 1 );
                this.Material.map.encoding = THREE.sRGBEncoding
                this.Material.needsUpdate = true
            }
            if (norMap) {
                this.Material.normalMap = norMap
            }
            if (aoMap) {
                this.Material.aoMap = aoMap
            }
            if (dispMap) {
                this.Material.displacementMap = dispMap
            }
            if (specMap) {
                this.Material.specularMap = specMap
            }
            this.cube = new THREE.Mesh( this.Geometry, this.Material )
        }
        this.cube.position.set(posX, posY, posZ)
        this.cube.rotation.x = THREE.Math.degToRad(rotX)
        this.cube.rotation.y = THREE.Math.degToRad(rotY)
        this.cube.rotation.z = THREE.Math.degToRad(rotZ)
        this.cube.castShadow = cast
        this.cube.receiveShadow = receive
    }
}

export default cubeObject