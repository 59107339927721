import * as THREE from "three"
import { THREEGLTFLoader} from 'three-loaders'
import cubeObject from './components/cubeObject'
import cylinderObject from './components/cylinderObject'
import PointLightObject from './components/PointLightObject'
import web_main_title_new from '../assets/maps/web/main_title_new.png'
import web_main_title_new_spec from '../assets/maps/web/main_title_new_spec.png'
import web_background from '../assets/maps/background.png'
import transparent from '../assets/maps/transparent.png'

class Web {
    constructor() {
        // === 필수 변수 선언 ===
        this.scene = new THREE.Scene()
    }
    setting = () => {
        this.GLTFloader = new THREEGLTFLoader()
        this.textureLoader = new THREE.TextureLoader()
        this.cubeTextureLoader = new THREE.CubeTextureLoader()
        const mainlightObject1 = new PointLightObject({
            posX: 50,
            posY: 40,
            posZ: 50,
            opacity: 0.4,
            distance: 100,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject2 = new PointLightObject({
            posX: 50,
            posY: -10,
            posZ: 20,
            opacity: 0.05,
            distance: 100,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject3 = new PointLightObject({
            posX: 40,
            posY: 7,
            posZ: 10,
            opacity: 0.1,
            distance: 100,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject4 = new PointLightObject({
            posX: 10,
            posY: 20,
            posZ: 90,
            opacity: 0.05,
            distance: 130,
            penumbra: 1,
            cast: false,
        })
        const mainlightObject5 = new PointLightObject({
            posX: 30,
            posY: -13,
            posZ: 40,
            opacity: 0.1,
            distance: 130,
            penumbra: 1,
            cast: false,
        })
        this.scene.add(
            mainlightObject1.light,
            mainlightObject2.light,
            mainlightObject3.light,
            mainlightObject4.light,
            mainlightObject5.light,
        )
    }
    draw = async () => {
        const panels =
        [
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -2,
                specular: 0x333333,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -4,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(web_main_title_new),
                frontSpecMap: this.textureLoader.load(web_main_title_new_spec),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -6,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -8,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -10,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -12,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -14,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -16,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -18,
                color: 0xffffff,
                emissive: 0x333333,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -20,
                color: 0xffffff,
                emissive: 0x000000,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
            new cubeObject({
                width: 48,
                height: 27,
                posZ: -22,
                color: 0xffffff,
                emissive: 0x444444,
                opacity: 1,
                mapEachSide: true,
                frontMap: this.textureLoader.load(transparent),
                sideMap: this.textureLoader.load(web_background),
                backMap: this.textureLoader.load(transparent),
            }),
        ]
        for(let i = 0; i < panels.length; i++) {
            this.scene.add(panels[i].cube)
            if(i === 1) {
                panels[i].cube.name = 'web'
            }
        }

        const makeLine = (posZ = -2) => {
            let cylinder1 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: -12,
                posY: 33,
                posZ: posZ,
                opacity: 0.7,
                color: 0x000000,
            })
            let cylinder2 = new cylinderObject({
                radius: 0.005,
                height: 50,
                posX: 12,
                posY: 33,
                posZ: posZ,
                opacity: 0.7,
                color: 0x000000,
            })
            this.scene.add(
                cylinder1.cylinder,
                cylinder2.cylinder,
            )
            return 0
        }
        for (let i = 1; i < 12; i++) {
            makeLine(i * -2)
        }
    }
}

export default Web;