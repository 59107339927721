import $ from 'jquery'

class CircularAudioWave {
    constructor(elem, opts = {}) {
        this.opts = opts;
        this.maxChartValue = 240;
        this.minChartValue = 0;
        this.visualizer = elem;
        this.playing = false;
        this.lineColorOffset = 0;
        this.tick = 0;

        // check if the default naming is enabled, if not use the chrome one.
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;

        if (!window.AudioContext) {
            alert('Your browser does not support Web Audio API');
        } else {
            this.context = new AudioContext();
            this.offlineContext = new OfflineAudioContext(2, 30 * 44100, 44100);
            this.sourceNode = this.context.createBufferSource();
            this.offlineSource = this.offlineContext.createBufferSource();
            this.sourceNode.loop = !!this.opts.loop;
            this.analyser = this.context.createAnalyser();

            // this.gainNode = this.context.createGain();
            // this.gainNode.gain.value = 0.1;
            // this.sourceNode.connect(this.gainNode)
            // this.gainNode.connect(this.context.destination);
            // console.log(this.context)
            // console.log(this.gainNode)
        }
    }
    loadAudio(filePath) {
        this.filePath = filePath;
        this._setupAudioNodes();
        this._setupOfflineContext();
        var request = new XMLHttpRequest();
        request.open('GET', filePath, true);
        request.responseType = 'arraybuffer';
        request.send();
        return new Promise((resolve, reject) => {
            request.onload = () => {
                // Preprocess buffer for bpm
                this.offlineContext.decodeAudioData(request.response, buffer => {
                    // console.log("buffer")
                    // console.log(buffer)
                    this.sourceNode.buffer = buffer;
                    this.offlineSource.buffer = buffer;
                    this.offlineSource.start(0);
                    this.offlineContext.startRendering();
                });

                this.offlineContext.oncomplete = e => {
                    let buffer = e.renderedBuffer;
                    this.bpm = this._getBPM([buffer.getChannelData(0), buffer.getChannelData(1)]);

                    this._init();
                    resolve();
                };

            };
        })
    }
    _init() {
        this._debouncedDraw = this._debounce(this._drawAnimation.bind(this), 25);
    }

    play() {
        if (this.sourceNode && this.sourceNode.buffer) {
            this.playing = true;
            this.sourceNode.start(0);
            this._debouncedDraw();
        } else {
            console.log('Audio is not ready');
        }
    }
    // TODO
    pause() {
    }
    destroy() {
    }
    reset() {
        this.tick = 0;
        this._init();
    }
    // TODO: Allow callback
    onended() {
        if (!this.opts.loop) {
            this.playing = false;
            this.context.close();
            this.sourceNode.buffer = null;
            this.offlineSource.buffer = null;
            this.reset();

            this.context = new AudioContext();
            this.offlineContext = new OfflineAudioContext(2, 30 * 44100, 44100);
            this.sourceNode = this.context.createBufferSource();
            this.offlineSource = this.offlineContext.createBufferSource();
            this.analyser = this.context.createAnalyser();
            this.loadAudio(this.filePath);
        }
    }
    _setupAudioNodes() {
        this.analyser.smoothingTimeConstant = 0.3;
        this.analyser.fftSize = 2048;

        this.sourceNode.connect(this.analyser);

        this.sourceNode.connect(this.context.destination);
        this.sourceNode.onended = this.onended.bind(this);
    }

    _setupOfflineContext() {
        // Beats generally occur around the 100 to 150 hz range.
        let lowpass = this.offlineContext.createBiquadFilter();
        lowpass.type = "lowpass";
        // lowpass.settar
        lowpass.frequency.setValueAtTime(150, 0);
        lowpass.Q.setValueAtTime(1, 0);

        this.offlineSource.connect(lowpass);

        let highpass = this.offlineContext.createBiquadFilter();
        highpass.type = "highpass";
        highpass.frequency.setValueAtTime(100, 0);
        highpass.Q.setValueAtTime(1, 0);
        lowpass.connect(highpass);
        highpass.connect(this.offlineContext.destination);
    }

    _drawAnimation() {
        let freqData = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(freqData);
        this._draw(freqData);
        requestAnimationFrame(this._debouncedDraw.bind(this));
    }

    _draw(freqData) {
        if (this.playing) {
            let waveData = this._generateWaveData(freqData)
            // this.chartOption.series[0].data = waveData.data
            // test
            // max = 20

            for(let i = 0; i < this.visualizer.children().length; i++) {
                $('#equalizer div:nth-child(' + String(this.visualizer.children().length - i) + ")").css(
                    'height', waveData.waveData[i * 10][0] / this.maxChartValue * 20
                )
            }

            this.tick++;
        }
    }
    _generateWaveData(data) {
        let waveData = [];
        for (let i = 0; i <= 360; i++) {
            let freq = data[i];
            var r = (((freq - 0) * (this.maxChartValue - this.minChartValue)) / (255 - 0)) + this.minChartValue;
            waveData.push([r, i]);
        }
        waveData.push([waveData[0][0], 360]);
        return {
            waveData
        };
    };


    _getBPM(data) {
        let partSize = 22050,
            parts = data[0].length / partSize,
            peaks = [];

        for (let i = 0; i < parts; i++) {
            let max = 0;
            for (let j = i * partSize; j < (i + 1) * partSize; j++) {
                let volume = Math.max(Math.abs(data[0][j]), Math.abs(data[1][j]));
                if (!max || (volume > max.volume)) {
                    max = {
                        position: j,
                        volume: volume
                    };
                }
            }
            peaks.push(max);
        }

        peaks.sort((a, b) => {
            return b.volume - a.volume;
        });
        peaks = peaks.splice(0, peaks.length * 0.5);
        peaks.sort((a, b) => {
            return a.position - b.position;
        });

        let groups = [];
        peaks.forEach((peak, index) => {
            for (let i = 1;
                (index + i) < peaks.length && i < 10; i++) {
                let group = {
                    bpm: (60 * 44100) / (peaks[index + i].position - peak.position),
                    count: 1
                };

                while (group.bpm < 90) {
                    group.bpm *= 2;
                }

                while (group.bpm > 180) {
                    group.bpm /= 2;
                }

                group.bpm = Math.round(group.bpm);

                if (!(groups.some(interval => {
                        return (interval.bpm === group.bpm ? interval.count++ : 0);
                    }))) {
                    groups.push(group);
                }
            }
        });

        let bpm = groups.sort((intA, intB) => {
            return intB.count - intA.count;
        })[0].bpm;
        // console.log('bpm:', bpm);

        return bpm;
    }

    _debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
}

export default CircularAudioWave