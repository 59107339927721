import * as THREE from "three";

class SpotLightObject {
    constructor({
        posX = 0, posY = 0, posZ = 0, tarX = 0, tarY = 0, tarZ = 0, cast = true,
        color = 0xffffff, opacity = 1,
        distance = 0, angle = Math.PI/2, penumbra = 0,
        shadowMapSizeWidth = 512,
        shadowMapSizeHeight = 512,
        shadowCameraNear = 0.5,
        shadowCameraFar = 500,
        shadowRadius = 8,
    }) {
        this.light = new THREE.SpotLight( color, opacity, distance, angle, penumbra)
        this.light.position.set( posX, posY, posZ )
        this.light.target.position.set( tarX, tarY, tarZ );
        this.light.castShadow = cast
        this.light.shadow.mapSize.width = shadowMapSizeWidth
        this.light.shadow.mapSize.height = shadowMapSizeHeight
        this.light.shadow.camera.near = shadowCameraNear
        this.light.shadow.camera.far = shadowCameraFar
        this.light.shadow.radius = shadowRadius
        this.helper = new THREE.SpotLightHelper( this.light )
    }
}

export default SpotLightObject