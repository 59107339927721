import img0 from '../assets/images/p5/img0.png'
import img00 from '../assets/images/p5/img00.png'
import img01 from '../assets/images/p5/img01.png'
import img000 from '../assets/images/p5/img000.png'
import img001 from '../assets/images/p5/img001.png'
import img010 from '../assets/images/p5/img010.png'
import img011 from '../assets/images/p5/img011.png'
import myFont from '../assets/fonts/Helvetica.ttf'
import $ from 'jquery'

class Sketch {
    constructor() {
        this.pixelSteps = 35 // Amount of pixels to skip
    }
    setup = async (p5, canvasParentRef) => {
        this.p5 = p5
        this.particles = []
        this.drawAsPoints = true
        this.images = []
        this.imageIndex = "img0"
        this.bgColor = p5.color(7, 40)
        this.fontName = p5.loadFont(myFont)
        this.page = 0

        this.w = window.innerWidth
        this.h = window.innerHeight
        this.width = window.innerWidth
        this.height = window.innerHeight
        p5.createCanvas(this.width, this.height).parent(canvasParentRef)
        p5.background(0)
        this.images.push("img0")
        this.images.push("img00")
        this.images.push("img01")
        this.images.push("img000")
        this.images.push("img001")
        this.images.push("img010")
        this.images.push("img011")

        this.img0 = p5.loadImage(img0)
        this.img00 = p5.loadImage(img00)
        this.img01 = p5.loadImage(img01)
        this.img000 = p5.loadImage(img000)
        this.img001 = p5.loadImage(img001)
        this.img010 = p5.loadImage(img010)
        this.img011 = p5.loadImage(img011)
    }
    draw = (p5) => {
        // 배경 칠하기
        p5.fill(this.bgColor)
        p5.noStroke()
        p5.rect(0, 0, this.width * 2, this.height * 2)
        // 파티클 실행 부분
        for (let x = this.particles.length-1; x > -1; x--) {
            // 파티클 이동 및 그리기
            this.particle = this.particles[x]
            this.particle.move(p5)
            this.particle.draw(p5)
            // 테두리 밖으로 나오면 파티클 삭제
            if (this.particle.isKilled) {
                if (this.particle.pos.x < 0 || this.particle.pos.x > this.width || this.particle.pos.y < 0 || this.particle.pos.y > this.height) {
                    this.particles.splice(x, 1); // 이거 오브젝트라 숫자로 변경해야함
                }
            }
        }
    }
    // 다음 단어로 변환
    mousePressed = (btId) => {
        if (this.imageIndex.length >= 6) {
            this.imageIndex = "img0"
        }
        else if (btId === 0) {
            this.imageIndex += "0"
        }
        else if (btId === 1) {
            this.imageIndex += "1"
        }
        this.nextWord()
    }
    // 키 누르면 배경색 변환
    // keyPressed = (p5) => {
    //     this.drawAsPoints = (! this.drawAsPoints);
    //     if (this.drawAsPoints) {
    //         p5.background(0);
    //         this.bgColor = p5.color(0, 40);
    //     } else {
    //         p5.background(255);
    //         this.bgColor = p5.color(255, 100);
    //     }
    // }
    windowResized = (p5, canvasParentRef) => {
        this.setup(p5, canvasParentRef)
    }
    // 화면 중앙에서 (width+height)/2 의 거리를 가진 랜덤 장소를 골라줌
    generateRandomPos = (p5, x, y, mag) => {
        let randomDir = p5.createVector(p5.random(0, this.width), p5.random(0, this.height));

        let pos = p5.createVector(x, y);
        pos.sub(randomDir);
        pos.normalize();
        pos.mult(mag);
        pos.add(x, y);

        return pos;
    }
    // 다음 단어 그리기
    nextWord = () => {
        let pg = this.p5.createGraphics(this.width, this.height)
        pg.fill(0)
        let imageNumber = this.imageIndex
        // 매개변수에 따라 이미지 변화
        const button = () => {
            $("#btWrap").css("justify-content", "center")
            $("#bt1").css("display", "none")
        }
        const buttons = () => {
            $("#btWrap").css("justify-content", "space-between")
            $("#bt1").css("display", "flex")
            $("#bt0").off("click")
        }
        if (imageNumber === "img0") {
            pg.image(this.img0, 0, 0, this.w, this.h)
            buttons()
            $("#bt0>p").html("Who are you?")
            $("#bt1>p").html("What is your position?")
        }
        else if (imageNumber === "img00") {
            pg.image(this.img00, 0, 0, this.w, this.h)
            buttons()
            $("#bt0>p").html("Tell me about your work experience.")
            $("#bt1>p").html("Let me know about you more.")
        }
        else if (imageNumber === "img01") {
            pg.image(this.img01, 0, 0, this.w, this.h)
            buttons()
            $("#bt0>p").html("How to contact you?")
            $("#bt1>p").html("Describe the website’s theme.")
        }
        else if (imageNumber === "img000") {
            pg.image(this.img000, 0, 0, this.w, this.h)
            button()
            $("#bt0>p").html("PROJECT")
        }
        else if (imageNumber === "img001") {
            pg.image(this.img001, 0, 0, this.w, this.h)
            button()
            $("#bt0>p").html("ABOUT ME")
        }
        else if (imageNumber === "img010") {
            pg.image(this.img010, 0, 0, this.w, this.h)
            button()
            $("#bt0>p").html("CONTACT")
        }
        else if (imageNumber === "img011") {
            pg.image(this.img011, 0, 0, this.w, this.h)
            button()
            $("#bt0>p").html("CONCEPT")
        }
        // 매개변수 에러 시, 사각형 출력
        else {
            this.p5.rect(50, 50, this.w-100, this.h-100)
        }
        // 픽셀로 로딩
        pg.loadPixels()

        // 컬러 랜덤 지정
        let newColor = this.p5.color(this.p5.random(255.0, 255.0), this.p5.random(255.0, 255.0), this.p5.random(255.0, 255.0))

        let particleCount = this.particles.length
        let particleIndex = 0

        let coordsIndexes = []
        for (let i = 0; i < (this.width*this.height)-1; i+= this.pixelSteps) {
            coordsIndexes.push(i)
        }

        for (let i = 0; i < coordsIndexes.length; i++) {
            let randomIndex = Math.floor(this.p5.random(0, coordsIndexes.length));
            let coordIndex = coordsIndexes[randomIndex];
            coordsIndexes.splice(randomIndex, 1);

            let x;
            let y;
            // 불투명 픽셀만 선택
            if (pg.pixels[coordIndex * 4 + 3] !== 0) {
                x = coordIndex % this.width;
                y = coordIndex / this.width;

                if (particleIndex < particleCount) {
                    this.newParticle = this.particles[particleIndex];
                    this.newParticle.isKilled = false;
                    particleIndex += 1;
            } else {
                this.newParticle = new Particle(this.p5);

                let randomPos = this.generateRandomPos(this.p5, this.width/2, this.height/2, (this.width+this.height)/2);
                this.newParticle.pos.x = randomPos.x;
                this.newParticle.pos.y = randomPos.y;

                this.newParticle.maxSpeed = this.p5.random(3.0, 8.0);
                this.newParticle.maxForce = this.newParticle.maxSpeed*0.100;
                this.newParticle.particleSize = this.p5.random(7, 15);
                this.newParticle.colorBlendRate = this.p5.random(0.0025, 0.03);

                this.particles.push(this.newParticle);
            }

            this.newParticle.startColor = this.p5.lerpColor(this.newParticle.startColor, this.newParticle.targetColor, this.newParticle.colorWeight);
            this.newParticle.targetColor = newColor;
            this.newParticle.colorWeight = 0;

            this.newParticle.target.x = x;
            this.newParticle.target.y = y;
            }
        }

        // 다음 그림에 쓰일 파티클이 현재 파티클보다 적으면 삭제
        if (particleIndex < particleCount) {
            for (let i = particleIndex; i < particleCount; i++) {
                this.particle = [];
                this.particle = this.particles[i];
                this.particle.kill(this.p5, this.w, this.h);
            }
        }
    }
}

export default Sketch

// 파티클 클래스
const Particle = class {
    constructor(p5, pos, vel, acc, target, closeEnoughTarget, maxSpeed, maxForce, particleSize, isKilled, startColor, targetColor, colorWeight, colorBlendRate) {
        this.pos = p5.createVector(0, 0)
        this.vel = p5.createVector(0, 0)
        this.acc = p5.createVector(0, 0)
        this.target = p5.createVector(0, 0)

        this.closeEnoughTarget = 50
        this.maxSpeed = 20.0
        this.maxForce = 5.0
        this.particleSize = 20
        this.isKilled = false

        this.startColor = p5.color(0)
        this.targetColor = p5.color(0)
        this.colorWeight = 0
        this.colorBlendRate = 0.025
    }
    move(p5) {
        let proximityMult = 2.0
        let distance = p5.dist(this.pos.x, this.pos.y, this.target.x, this.target.y);
        if (distance < this.closeEnoughTarget) {
            proximityMult = distance/this.closeEnoughTarget;
        }

        let towardsTarget = p5.createVector(this.target.x, this.target.y);
        towardsTarget.sub(this.pos);
        towardsTarget.normalize();
        towardsTarget.mult(this.maxSpeed*proximityMult);
    
        let steer = p5.createVector(towardsTarget.x, towardsTarget.y);
        steer.sub(this.vel);
        steer.normalize();
        steer.mult(this.maxForce);
        this.acc.add(steer);
    
        this.vel.add(this.acc);
        this.pos.add(this.vel);
        this.acc.mult(0);
    }

    draw(p5) {
        let currentColor = p5.lerpColor(this.startColor, this.targetColor, this.colorWeight);
        // drawAsPoints는 keyPressed() 함수와 관련. 점으로 그려줌
        // if (drawAsPoints) {
        p5.stroke(currentColor);
        p5.point(this.pos.x, this.pos.y);
        // } else {
        //     p5.noStroke();
        //     p5.fill(currentColor);
        //     p5.ellipse(this.pos.x, this.pos.y, this.particleSize, this.particleSize);
        // }
    
        if (this.colorWeight < 1.0) {
            this.colorWeight = p5.min(this.colorWeight+this.colorBlendRate, 1.0);
        }
    }

    // 파티클 삭제
    kill(p5, width, height) {
        if (!this.isKilled) {
            // Set its target outside the scene
            let randomPos = this.generateRandomPos(p5, width/2, height/2, (width+height)/2);
            this.target.x = randomPos.x;
            this.target.y = randomPos.y;
    
            // Begin blending its color to black
            this.startColor = p5.lerpColor(this.startColor, this.targetColor, this.colorWeight);
            this.targetColor = p5.color(0);
            this.colorWeight = 0;
    
            this.isKilled = true;
        }
    }
    // 화면 중앙에서 (width+height)/2 의 거리를 가진 랜덤 장소를 골라줌
    generateRandomPos = (p5, x, y, mag) => {
        let randomDir = p5.createVector(p5.random(0, this.width), p5.random(0, this.height));
        
        let pos = p5.createVector(x, y);
        pos.sub(randomDir);
        pos.normalize();
        pos.mult(mag);
        pos.add(x, y);
        
        return pos;
    }
}